$(document).ready(function(){
//match height
$(function() {
	$('.offer_card_name').matchHeight({
    byRow: true,
    property: 'height',
    target: null,
    remove: false
});
});

//some scroll magic
$(window).scroll(function () {
    var $this = $(this),
        $head = $('#floatcta');
    if ($this.scrollTop() > 500) {
       $head.addClass('visiable');
		} else {
       $head.removeClass('visiable');
    }
});

$(window).scroll(function () {
    var $this = $(this),
        $head = $('#cta');
    if ($this.scrollTop() > 900) {
       $head.addClass('fixed');
    } else {
       $head.removeClass('fixed');
    }
});





//tell placeholder
$(function(){
$('.phone_mask').mask('+7(000) 000-00-00');
});


//slider
$('.tech_slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  prevArrow: $(".left_arrow_tech_block"),
  nextArrow: $(".right_arrow_tech_block"),
  autoplay: true
});

$('.mat_slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 2000,
  prevArrow: $(".left_arrow_mat_block"),
  nextArrow: $(".right_arrow_mat_block"),
  autoplay: true
});


// Cache the Window object
	$window = $(window);

   $('section[data-type="background"]').each(function(){
     var $bgobj = $(this); // assigning the object

      $(window).scroll(function() {

		// Scroll the background at var speed
		// the yPos is a negative value because we're scrolling it UP!
		var yPos = -($window.scrollTop() / $bgobj.data('speed'));

		// Put together our final background position
		var coords = '50% '+ yPos + 'px';

		// Move the background
		$bgobj.css({ backgroundPosition: coords });

}); // window scroll Ends

 });


//Create HTML5 elements for IE's
document.createElement("article");
document.createElement("section");




//scroll
$('.go_to').click( function(){ // ловим клик по ссылке с классом go_to
	var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
        if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
	    $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500); // анимируем скроолинг к элементу scroll_el
        }
	    return false; // выключаем стандартное действие
    });



//intro
$('.intro_cta').click(function(){
$('.intro_cta-more').toggle('off')
});


//card
$('.helper_1').click(function(){
$('.ocb_1').css('display' , 'none')
$('#ofcba1').css('display' , 'block')
});

$('#btn_offer_1').click(function(){
$('.ocb_1').css('display' , 'none')
$('#ofcba1').css('display' , 'block')
});

$('.helper_close_1').click(function() {
$('.ocb_1').css('display' , 'block')
$('#ofcba1').css('display' , 'none')
});


$('.helper_2').click(function(){
$('.ocb_2').css('display' , 'none')
$('#ofcba2').css('display' , 'block')
});

$('#btn_offer_2').click(function(){
$('.ocb_2').css('display' , 'none')
$('#ofcba2').css('display' , 'block')
});

$('.helper_close_2').click(function() {
$('.ocb_2').css('display' , 'block')
$('#ofcba2').css('display' , 'none')
});

$('.cta_close').click(function() {
$('.cta_float').css('display' , 'none')
});


//modal call
$("#modal").iziModal();
$(document).on('click', '#cta_float', function (event) {
    event.preventDefault();
    // $('#modal').iziModal('setZindex', 99999);
    // $('#modal').iziModal('open', { zindex: 99999 });
    $('#modal_sale').iziModal('open');
});

//modal cfg
$("#modal_sale").iziModal({
    title: 'КУПОН НА СКИДКУ 50%',
    subtitle: '',
    headerColor: '#2768b9',
    background: null,
    theme: '',  // light
    icon: null,
    iconText: null,
    iconColor: '',
    rtl: false,
    width: 600,
    top: null,
    bottom: null,
    borderBottom: true,
    padding: 0,
    radius: 3,
    zindex: 999,
    iframe: false,
    iframeHeight: 400,
    iframeURL: null,
    focusInput: true,
    group: '',
    loop: false,
    arrowKeys: true,
    navigateCaption: true,
    navigateArrows: true, // Boolean, 'closeToModal', 'closeScreenEdge'
    history: false,
    restoreDefaultContent: false,
    autoOpen: false, // Boolean, Number
    bodyOverflow: false,
    fullscreen: false,
    openFullscreen: false,
    closeOnEscape: true,
    closeButton: true,
    appendTo: 'false', // or false
    appendToOverlay: 'body', // or false
    overlay: true,
    overlayClose: true,
    overlayColor: 'rgba(0, 0, 0, 0.4)',
    timeout: false,
    timeoutProgressbar: false,
    pauseOnHover: false,
    timeoutProgressbarColor: 'rgba(255,255,255,0.5)',
    transitionIn: 'comingIn',
    transitionOut: 'comingOut',
    transitionInOverlay: 'fadeIn',
    transitionOutOverlay: 'fadeOut',
    onFullscreen: function(){},
    onResize: function(){},
    onOpening: function(){},
    onOpened: function(){},
    onClosing: function(){},
    onClosed: function(){},
    afterRender: function(){}
});





  });
